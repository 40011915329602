<template>
  <v-main>
    <v-card class="mx-auto my-12 mb-15" max-width="374" color="#fdf9f9">
      <v-card-title class="justify-center">
        LOGIN
      </v-card-title>
      <v-card-text>
        <p v-if="incorrectAuth">Usuário ou senha incorretos.</p>
        <v-form v-on:submit.prevent="login" ref="form">
          <v-container>
            <v-row dense>
              <v-col>
                <p class="mb-2 pb-0">Entre com seu CPF</p>
                <v-text-field outlined label="Usuário" v-model="username" v-mask="'###.###.###-##'"
                  placeholder="000.000.000-00" hide-details :rules="[cpfRule]" clearable required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="mb-0 pb-0">
              <v-col class="mb-0 pb-0" cols="12">
                <v-text-field outlined class="ma-0 pb-0" label="Senha" v-model="password"
                  :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'" :type="tipo ? 'text' : 'password'"
                  @click:append="tipo = !tipo" hide-details clearable required :rules="[(v) => !!v && v.length > 0]">
                </v-text-field>

              </v-col>
              <v-col class="text-right mb-10">
                <RecuperaSenha :item-corrente="username" />

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row align-self="left" justify="space-around" dense>
            <v-btn block id="login-1" :loading="loading" color="rgb(94, 164, 176)" dark @click.stop="entrar">
              <v-icon left>mdi mdi-login</v-icon>
              ENTRAR
            </v-btn>
            <v-btn v-show="false" id="login-1" color="secondary" @click.stop="cadastrar" dark>
              <v-icon left>mdi mdi-account-plus</v-icon>
              CADASTRE-SE
            </v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="loading" height="300" width="500" :color="cor">
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="fechaLoading" text>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <LinksUteis/>
  </v-main>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Login',
  components: {
    RecuperaSenha: () => import("./main/RecuperaSenha.vue"),
    LinksUteis: () => import("./LinksUteis.vue")
},
  data: () => ({
    info: [],
    username: "",
    password: "",
    incorrectAuth: false,
    tipo: false,
    propUser: ""
  }),
  computed: {
    ...mapState(['count', 'cor', 'mensagem']),
    ...mapGetters(['loading'])
  },
  methods: {
    cpfRule(input) {
      return input?.length === 14 && this.isValidCPF(input)
    },
    isValidCPF(strCPF) {
      const getNextDigit = (lstValue, curValue, curIdx) => lstValue + curValue * (9 - curIdx)
      let numValues = Array.from(strCPF.match(/\d/g), Number)
      let uniqueValues = new Set(numValues)
      let [, , ...arrCalc] = numValues.reverse()

      for (let i = 0; i < 2; i++) {
        arrCalc = [arrCalc.reduce(getNextDigit, 0) % 11 % 10, ...arrCalc]
      }

      return arrCalc.toString() === numValues.toString() && uniqueValues.size > 1
    }
    ,
    login(e) {
      if (e.keyCode === 13) {
        this.entrar()
      }

    },
    fechaLoading() {
      this.$store.dispatch('btnLoading', false)
      this.$store.dispatch('cor', '')
      this.$store.dispatch('mensagem', '')
    },
    entrar() {
      if (!this.$refs.form.validate()) {
        alert('Campos obrigatórios não foram preenchidos.')
        return
      }
      this.$store.dispatch('btnLoading', true)
      this.$store.dispatch('mensagem', "Solicitando autenticação...")
      this.$store.dispatch('cor', "blue")

      //realiza o login 
      this.$store.dispatch('userLogin', {
        method: 'post',
        url: 'base/token/',
        data: { "username": this.username, "password": this.password }
      })
    }

  },
  mounted() {
    this.$store.dispatch('btnLoading', false)
  }
}
</script>